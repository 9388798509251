import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Field, Form } from 'formik';
import InputFile from '../components/InputFile';
import ErrorFocus from '../components/ErrorFocus';
import Data from '../modules/Data';
import Schema from '../modules/Schema';

const Uploads = (props) => {
	const intl = useIntl();
	const formikFormRef = useRef();
	const { data } = props.config;
	const schema = Schema.load(Schema.uploads, data);
	const initialValues = schema.cast(Data.dataToValues(Data.uploads, data));
	const { fields } = schema;

	const saveForm = (values) => {
		Data.saveSection(Data.uploads, values, data);
		props.onSave();
	};

	return (
		<Formik
			validateOnMount
			initialValues={initialValues}
			validationSchema={schema}
			innerRef={formikFormRef}
			onSubmit={saveForm}
			enableReinitialize
		>
			{({ values }) => (
				<Form>
					{fields.DOK01 && (
						<>
							<h3>
								<FormattedMessage id="forms.id_pass"></FormattedMessage>
							</h3>
							<p
								dangerouslySetInnerHTML={{
									__html: intl.formatMessage({ id: 'forms.id_pass_xplain' }),
								}}
							></p>
							<Field
								component={InputFile}
								name="DOK01"
								secondImage="DOK01B"
								label="forms.id_pass_caption"
								format="pdf"
							/>
							{values.DOK01B.BASE64 !== 'S' && values.DOK01.allowDrop === true && (
								<Field
									component={InputFile}
									name="DOK01B"
									secondImageFor="DOK01"
									label="forms.second_image_caption"
									format="jpg"
								/>
							)}
						</>
					)}

					{fields.DOK02 && (
						<>
							<h3>
								<FormattedMessage id="forms.passfoto"></FormattedMessage>
							</h3>
							<p
								dangerouslySetInnerHTML={{
									__html: intl.formatMessage({ id: 'forms.passfoto_xplain' }),
								}}
							></p>
							<Field component={InputFile} name="DOK02" label="forms.passfoto_caption" format="jpg" />
						</>
					)}

					{fields.DOK03 && (
						<>
							<h3>
								<FormattedMessage id="forms.betreibungen"></FormattedMessage>
							</h3>
							<p
								dangerouslySetInnerHTML={{
									__html: intl.formatMessage({ id: 'forms.betreibungen_xplain' }),
								}}
							></p>
							<Field component={InputFile} name="DOK03" label="forms.documents_caption" format="pdf" />
						</>
					)}

					{fields.DOK04 && (
						<>
							<h3>
								<FormattedMessage id="forms.strafregister"></FormattedMessage>
							</h3>
							<p
								dangerouslySetInnerHTML={{
									__html: intl.formatMessage({ id: 'forms.strafregister_xplain' }),
								}}
							></p>
							<Field component={InputFile} name="DOK04" label="forms.documents_caption" format="pdf" />
						</>
					)}

					{fields.DOK05 && (
						<>
							<h3>
								<FormattedMessage id="forms.nda"></FormattedMessage>
							</h3>
							<p
								dangerouslySetInnerHTML={{
									__html: intl
										.formatMessage({
											id: 'forms.nda_xplain',
										})
										.replace('{link}', `${process.env.APP_SERVER}/download/DOK21`),
								}}
							></p>
							<Field
								component={InputFile}
								name="DOK05"
								secondImage="DOK05B"
								label="forms.documents_caption"
								format="pdf"
							/>
							{values.DOK05B.BASE64 !== 'S' && values.DOK05.allowDrop === true && (
								<Field
									component={InputFile}
									name="DOK05B"
									secondImageFor="DOK05"
									label="forms.second_image_caption"
									format="jpg"
								/>
							)}
						</>
					)}

					{fields.DOK06 && (
						<>
							<h3>
								<FormattedMessage id="forms.sicherheitserklaerung"></FormattedMessage>
							</h3>
							<p
								dangerouslySetInnerHTML={{
									__html: intl.formatMessage({ id: 'forms.sicherheitserklaerung_xplain' }),
								}}
							></p>
							<Field component={InputFile} name="DOK06" label="forms.documents_caption" format="pdf" />
						</>
					)}

					<ErrorFocus isActive={props.isOpen} />
					<div className="button-group move-down">
						<button type="button" className="button secondary" onClick={props.onClose}>
							<FormattedMessage id="button.cancel"></FormattedMessage>
						</button>
						<button type="submit" className="button">
							<FormattedMessage id="button.save"></FormattedMessage>
						</button>
					</div>
					<div className="required">
						<FormattedMessage id="forms.required_fields"></FormattedMessage>
					</div>
					{false && process.env.DEV && (
						<div>
							<hr />
							<pre>{JSON.stringify(values, 0, 2)}</pre>
							<pre>{JSON.stringify(props.config.data, 0, 2)}</pre>
						</div>
					)}
				</Form>
			)}
		</Formik>
	);
};

Uploads.propTypes = {
	config: PropTypes.object,
	onClose: PropTypes.func,
	onSave: PropTypes.func,
	isOpen: PropTypes.bool,
};

export default Uploads;
