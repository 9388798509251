import axios from 'axios';
import { isEqual, isEmpty, padStart } from 'lodash';
import Schema from './Schema';

const personal = 1;
const uploads = 2;
const maxUploadId = 6;

class Data {
	static init(data) {
		try {
			// uploads
			for (let index = 1; index <= maxUploadId; index += 1) {
				const key = `DOK${padStart(index, 2, '0')}`;
				data[key] = { ART: `${key}`, BASE64: '', DATEINAME: '', REQUIRED: data[key] };
			}
			data.DOK01B = { ART: 'DOK01B', BASE64: '', DATEINAME: '', REQUIRED: '' };
			data.DOK05B = { ART: 'DOK05B', BASE64: '', DATEINAME: '', REQUIRED: '' };

			data.DOKUMENTE.forEach((key, index) => {
				if (data.DOKUMENTE[index].ART !== 'DOK21')
					data[data.DOKUMENTE[index].ART] = {
						...data.DOKUMENTE[index],
						REQUIRED: data[data.DOKUMENTE[index].ART].REQUIRED,
					};
			});

			// save states
			if (typeof data.GUI01 === 'undefined') data.GUI01 = ''; // personal
			if (typeof data.GUI02 === 'undefined') data.GUI02 = ''; // uploads
		} catch (error) {
			console.log(error);
		}

		return data;
	}

	static saveSection(section, values, data) {
		switch (section) {
			case personal:
				values.GUI01 = 'X';
				if (values.LAND1 !== 'CH') values.STATE = '';
				break;

			case uploads:
				values.GUI02 = 'X';
				break;

			default:
				console.log('save: unknown section', section);
				return data;
		}

		return Data.valuesToData(section, values, data);
	}

	static valuesToData(section, values, data) {
		let changed = {};
		const deleted = [];
		// if (process.env.DEV) console.log('valuesToData', values);

		// change in place
		Object.keys(values).forEach((key) => {
			if (typeof values[key] !== 'undefined') {
				if (Array.isArray(values[key]) || !isEqual(data[key], values[key])) {
					changed[key] = values[key];
				}
				data[key] = values[key];
			}
		});
		// if (process.env.DEV) console.log('valuesToData changeset', changed);

		if (!isEmpty(changed)) {
			// number to strings, just to be safe
			const replacer = (key, value) => {
				if (typeof value === 'number') {
					return value.toString();
				}
				return value;
			};
			changed = JSON.parse(JSON.stringify(changed, replacer));

			// if (process.env.DEV) console.log({ changed, deleted });
			if (!isEmpty(changed) || !isEmpty(deleted)) {
				axios.post(`${process.env.APP_SERVER}`, { action: 'save', changed, deleted }).then((result) => {
					// if (process.env.DEV) console.log('save', result.data);
				});
			}
		}

		// console.log(data);
		return data;
	}

	static dataToValues(section, data) {
		const result = {};

		switch (section) {
			case personal:
				return {
					ANRED: data.ANRED,
					VORNA: data.VORNA,
					NACHN: data.NACHN,
					GBDAT: data.GBDAT,
					STRAS: data.STRAS,
					PSTLZ: data.PSTLZ,
					ORT01: data.ORT01,
					LAND1: data.LAND1,
					STATE: data.STATE,
					NATIO: data.NATIO,
					SPRSL: data.SPRSL,
				};

			case uploads:
				for (let index = 1; index <= maxUploadId; index += 1) {
					const key = `DOK${padStart(index, 2, '0')}`;
					result[key] = data[key];
				}
				result.DOK01B = data.DOK01B;
				result.DOK05B = data.DOK05B;
				return result;

			default:
				console.log('Data.dataToValues: unknown section', section);
				return {};
		}
	}

	static validateSections(data) {
		return {
			personalDetailsIsValid: data.STATUS === 'EMPLOYEE_D' ? true : Data.validateSection(Data.personal, data),
			uploadsIsValid: Data.validateSection(Data.uploads, data),
		};
	}

	static validateSection(section, data) {
		let isValid = !!data[`GUI0${section}`];
		try {
			Schema.load(section, data).validateSync(Data.dataToValues(section, data));
		} catch (err) {
			isValid = false;
		}
		return isValid;
	}

	static areAllSectionsValid(state) {
		return state.personalDetailsIsValid && state.uploadsIsValid;
	}

	static get personal() {
		return personal;
	}

	static get uploads() {
		return uploads;
	}
}

export default Data;
