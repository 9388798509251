import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, useNavigate } from 'react-router-dom';
import { findIndex, padStart } from 'lodash';
import { format } from 'date-fns';
import axios from 'axios';
import ModalConfirm from './ModalConfirm';
import Layout from './Layout';
import Data from '../modules/Data';
import Schema from '../modules/Schema';
import { sleep } from '../modules/Utils';

const Summary = (props) => {
	const intl = useIntl();
	const navigate = useNavigate();
	const [isConfirmOpen, setIsConfirmOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { data } = props.config;
	const schemaUploads = Schema.load(Schema.uploads, data);

	const state = Data.validateSections(props.config.data);
	if (!Data.areAllSectionsValid(state)) {
		navigate('/home', { replace: true });
	}

	useEffect(() => {
		props.onConfigUpdate({ home: '/summary' });
	}, []);

	const handleConfirmOpen = () => setIsConfirmOpen(true);

	const handleConfirmOk = () => {
		// delete unused documents
		const toDelete = [];
		for (let index = 1; index < 6; index += 1) {
			const key = `DOK${padStart(index, 2, '0')}`;
			if (data[key].DATEINAME !== '' && !schemaUploads.fields[key]) {
				toDelete.push({ ART: key, BASE64: '', DATEINAME: '' });
			}
		}

		if (toDelete.length > 0) {
			axios.post(`${process.env.APP_SERVER}`, { action: 'delete', ZDOKU: toDelete });
		}

		// finalize submission
		setIsLoading(true);
		sleep(toDelete.length > 0 ? 100 : 0).then(() => {
			axios.post(`${process.env.APP_SERVER}`, { action: 'submit' }).then((result) => {
				if (result.data.result) {
					props.onConfigUpdate({ isCompleted: true, isAuthenticated: true, status: result.data.status });
					navigate('/confirmed', { replace: true });
				}
			});
		});
	};

	const handleConfirmCancel = () => setIsConfirmOpen(false);

	const label = (id) => {
		const message = intl.formatMessage({ id });
		return typeof message === 'string' ? message.replace('*', '') : id;
	};

	return (
		<Layout title="app.title" {...props}>
			<div className="content form">
				<div className="content-inner">
					<h1 className="smaller">
						<FormattedMessage id="summary.title"></FormattedMessage>
					</h1>
					<p>
						<FormattedMessage id="summary.text"></FormattedMessage>
					</p>
					<form onSubmit={() => false}>
						<div className="summary-panel">
							<h3>
								<FormattedMessage id="home.personal"></FormattedMessage>
							</h3>
							{data.STATUS === 'EMPLOYEE_D' ? (
								<table className="summary">
									<tbody>
										<tr>
											<th>{label('forms.vorname')}</th>
											<td>{data.VORNA}</td>
										</tr>
										<tr>
											<th>{label('forms.nachname')}</th>
											<td>{data.NACHN}</td>
										</tr>
										<tr>
											<th>{label('forms.geburtsdatum')}</th>
											<td>{format(new Date(data.GBDAT), 'dd.MM.yyyy')}</td>
										</tr>
									</tbody>
								</table>
							) : (
								<>
									<table className="summary">
										<tbody>
											<tr>
												<th>{label('forms.anrede')}</th>
												<td>{intl.messages.ANRED[findIndex(intl.messages.ANRED, { key: data.ANRED })].value}</td>
											</tr>
											<tr>
												<th>{label('forms.vorname')}</th>
												<td>{data.VORNA}</td>
											</tr>
											<tr>
												<th>{label('forms.nachname')}</th>
												<td>{data.NACHN}</td>
											</tr>
											<tr>
												<th>{label('forms.geburtsdatum')}</th>
												<td>{format(new Date(data.GBDAT), 'dd.MM.yyyy')}</td>
											</tr>
											<tr>
												<th>&nbsp;</th>
												<td></td>
											</tr>
											<tr>
												<th>{label('forms.strasse')}</th>
												<td>{data.STRAS}</td>
											</tr>
											<tr>
												<th>{label('forms.plzort')}</th>
												<td>{`${data.PSTLZ} ${data.ORT01}`}</td>
											</tr>
											<tr>
												<th>{label('forms.land')}</th>
												<td>{intl.messages.LANDX[findIndex(intl.messages.LANDX, { key: data.LAND1 })].value}</td>
											</tr>
											{data.STATE !== '' && (
												<tr>
													<th>{label('forms.kanton')}</th>
													<td>{intl.messages.KANTO[findIndex(intl.messages.KANTO, { key: data.STATE })].value}</td>
												</tr>
											)}
											<tr>
												<th>&nbsp;</th>
												<td></td>
											</tr>
											<tr>
												<th>{label('forms.staatsangeh')}</th>
												<td>{intl.messages.LANDX[findIndex(intl.messages.LANDX, { key: data.NATIO })].value}</td>
											</tr>
											<tr>
												<th>{label('forms.sprache')}</th>
												<td>{intl.messages.SPRSL[findIndex(intl.messages.SPRSL, { key: data.SPRSL })].value}</td>
											</tr>
										</tbody>
									</table>
									<div className="button-group single">
										<NavLink to="/home?personal-details" className="button secondary no-print">
											<FormattedMessage id="button.edit"></FormattedMessage>
										</NavLink>
									</div>
								</>
							)}
						</div>
						<div className="summary-panel">
							<h3>
								<FormattedMessage id="home.uploads"></FormattedMessage>
							</h3>
							<table className="summary">
								<tbody>
									{schemaUploads.fields.DOK01 && (
										<tr>
											<th>{label('forms.id_pass')}</th>
											<td>
												<div className="check"></div>
											</td>
										</tr>
									)}
									{schemaUploads.fields.DOK02 && (
										<tr>
											<th>{label('forms.passfoto')}</th>
											<td>
												<div className="check"></div>
											</td>
										</tr>
									)}
									{schemaUploads.fields.DOK03 && (
										<tr>
											<th>{label('forms.betreibungen')}</th>
											<td>
												<div className="check"></div>
											</td>
										</tr>
									)}
									{schemaUploads.fields.DOK04 && (
										<tr>
											<th>{label('forms.strafregister')}</th>
											<td>
												<div className="check"></div>
											</td>
										</tr>
									)}
									{schemaUploads.fields.DOK05 && (
										<tr>
											<th>{label('forms.nda')}</th>
											<td>
												<div className="check"></div>
											</td>
										</tr>
									)}
									{schemaUploads.fields.DOK06 && (
										<tr>
											<th>{label('forms.sicherheitserklaerung')}</th>
											<td>
												<div className="check"></div>
											</td>
										</tr>
									)}
								</tbody>
							</table>
							<div className="button-group single">
								<NavLink to="/home?uploads" className="button secondary no-print">
									<FormattedMessage id="button.edit"></FormattedMessage>
								</NavLink>
							</div>
						</div>
						<div className="button-group">
							<NavLink to="/home" className="button secondary">
								<FormattedMessage id="button.cancel"></FormattedMessage>
							</NavLink>
							<button type="button" className="button" onClick={handleConfirmOpen}>
								<FormattedMessage id="button.submit"></FormattedMessage>
							</button>
						</div>
					</form>
				</div>
			</div>
			<ModalConfirm
				isOpen={isConfirmOpen}
				isLoading={isLoading}
				handleConfirmOk={handleConfirmOk}
				handleConfirmCancel={handleConfirmCancel}
				title="summary.modal_sure.title"
				text="summary.modal_sure.text"
			/>
		</Layout>
	);
};

Summary.propTypes = {
	config: PropTypes.object,
	onConfigUpdate: PropTypes.func,
};

export default Summary;
